<!-- 专题活动 -->
<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>

        <div class="v2-g-w1200">
<!--            <div class=" menu v2-g-flex-row">-->
<!--                <span class="span" :class="{'sel':queryParam.code == '1015'}" @click="search('1015')">周爆款</span>-->
<!--                <span class="span" :class="{'sel':queryParam.code == '1014'}" @click="search('1014')">月排行</span>-->
<!--            </div>-->
            <div class="filter_btn_box_item">
                <div :class="['filter_btn', currentRule == index ? 'active_rule' : '']"
                     v-for="(rule, index) in orderRule" :key="index" @click="chooseRule(rule, index)">
                    <span>{{ rule.label }}</span>
                </div>
                <p class="xsTips"><span class="videoMsg">视频未看: {{noWatchVideoCount}}</span><span class="videoMsg">可领红包:￥{{totalToGetAmount}}</span></p>
              <!--<el-select v-model="sortType" size="small" placeholder="请选择" @change="changeSortType">-->
              <!--  <el-option-->
              <!--    v-for="item in sortTypeList"-->
              <!--    :key="item.value"-->
              <!--    :label="item.label"-->
              <!--    :value="item.value">-->
              <!--  </el-option>-->
              <!--</el-select>-->
            </div>
            <ProductList :list="list" :pageInfo="pageInfo" v-on:getdata="getDataByPage" :hideToolbar="true" @getGoodsList="getData"></ProductList>
        </div>
        <PageBottom></PageBottom>
    </div>
</template>
<style scoped lang="less">
.menu {
    background: #F1F1F4;
    height: 34px;
    align-items: center;
    margin:20px 0;
    cursor: pointer;
    .span {
        width: 75px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 13px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        margin-right: 10px;
        &.sel{
            background-color: #3875F6;
            color:#fff;
        }
    }
}
.filter_btn_box_item{
    display: flex;
    align-items: center;
    margin-top: 10px;

  ::v-deep {
    .el-input__inner {
      border-radius: 100px;
    }
  }

    .filter_btn {
      cursor: pointer;
      width: 162px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin-left: 4px;
      text-align: center;
      background: #F6F6F6;
      border-radius: 16px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #222222;
      margin-right: 10px;
    }
    .active_rule{
      border: 1px solid #3875F6;
      color: #3875F6;
      background: #F5F8FF;
    }
  .xsTips{
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    .videoMsg{
      color: rgba(255,0,0,0.9);
      margin-right: 10px;
    }
  }
}
</style>
<script>
import ProductList from "@/components-v2/product/product-list";
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import { purchaseQuantityProcessing } from '@/utils'
import { kv } from "../../mixin/kv"
export default {
    mixins: [kv],
    data() {
        return {
            list: [],
            pcImg: null,
            pageInfo: {
                total: 0,
                pageNum: 1,
                pageSize: 10
            },
            queryParam: {
                code: '1015',
                pageSize: 20,
                activity: null,
                isChineseMedicine:0,
                page: 1 //请求页
            },
            orderRule: [
              {
                label: "特价",
                value: 0,
                isActivity: true
              },
              {
                label: "视频红包",
                value: 1016,
                isActivity: true
              },
            // {
            //     label: "中药",
            //     value: 1,
            //     isActivity: true
            // }
            ],
            currentRule:0,
          sortType: null,
          sortTypeList: [
            {value: 10, label: "5元以下 周销售"},
            {value: 2, label: "5元以下 价格"},
          ],
            noWatchVideoCount:0,//未看视频总数
            totalToGetAmount:0,//总需领取金额
        };
    },
    components: {
        ProductList,
        PageTop,
        PageTop1,
        PageBottom
    },
    created() {
      // 首页banner胖东来跳转时，会传入 currentRule 参数为 1
      if (this.$route.params.currentRule && this.$route.params.currentRule == 1) {
        this.currentRule = 1;
        this.queryParam.sortType = 10;
        this.queryParam.activity = 4;
      }
      this.getRedBagData()
      this.getData();
      this.kv_visit("2", this.$route.params.id);
    },
    methods: {
        //获取红包数据type1学术视频 2视频红包
        async getRedBagData(){
            const res = await this.$getHttpClient().post(
                "/pjyy-deepexi-product-center/api/v3/product/PcProduct/countVideoInfo",{
                    type: 2
                });
            if (res.data.code == 200) {
                this.noWatchVideoCount = res.data.data.noWatchVideoCount
                this.totalToGetAmount = res.data.data.totalToGetAmount
            }
        },
        chooseRule(rule, index) {
            this.currentRule = index
            this.queryParam.isChineseMedicine = this.orderRule[index].value
            this.queryParam.sortType = index == 0 ? null : 10;
            this.queryParam.activity = index == 0 ? null : 4;
            this.pageInfo.pageNum = 0;
            this.pageInfo.total = 0;
            this.queryParam.page = 1;
            this.list = []
            this.getData()
        },
        changeSortType(val) {
          this.currentRule = null;
          this.queryParam.sortType = val;
          this.queryParam.activity = 4;
          this.list = []
          this.getData()
        },
        async getData() {
          let params = {
            page: this.queryParam.page,
            pageSize: this.queryParam.pageSize,
            isVideo: this.currentRule,  // 是否5元以下
          }
            const url = "/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/specialPromotionProduct";
            const result = await this.$getHttpClient().post(url, params);
            if (result.data.code == '200') {
                this.list = purchaseQuantityProcessing(result.data.data.list || []);
                this.pageInfo.total = result.data.data.total;
                this.pageInfo.pageNum = result.data.data.pageNum;
                this.pageInfo.pageSize = result.data.data.pageSize;
            }
        },
        search(code){
            this.queryParam.code=code;
            this.getData();
        },
        getDataByPage(data) {
            this.queryParam.page = data.pageNum;
            this.getData();
        }
    }
}
</script>
